import * as React from "react"
import { Link } from "gatsby"
import tsunamiImage from "../images/financialtsunami.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"

const blog2 = () => (
  <Layout>
    <SEO title="Catastrophic Financial Tsunami!" />
    <div class="container col-lg-6">
      <article class="blog-post mt-3">
        <h2 class="blog-post-title">Catastrophic Financial Tsunami!</h2>
        <p class="blog-post-meta">March 16 2021 by Paul</p>
        <img src={tsunamiImage} class="card-img-top mb-3" alt="..." />
        <p>
          The relocation market has been stressed financially like no one could
          imagine prior to COVID. This is after years of pressure on profits,
          increased compliance, and investment in areas such as technology which
          we cover in our Technology Race to where blog. It was not unusual for
          companies to run at a loss during the quiet times and replenish their
          finances during the peak months. How are those companies faring now?
        </p>
        <p>
          Cashflow is a key factor for companies across the relocation spectrum,
          so are we facing a series of companies running out of cash followed by
          the wider impact this would have across the market? Or are companies
          though impacted, still healthy and ready to take advantage of the
          increase in mobility when COVID is under control?
        </p>
        <p>
          It is surely a mixture of the two scenarios but given the financial
          stress in the market, refinancing, mergers, and acquisitions are
          surely being discussed along with new investors looking to enter the
          market.
        </p>
        <p>
          So, we are facing a time of uncertainty, consolidation and potentially
          companies leaving the relocation scene. But this could be an exciting
          time for the mobility industry as companies focus on their core
          strengths, potentially stop trying to be everything to everyone! Our
          Ecosystem The Future blog covers the potential benefits of working
          with multiple providers who each focus on key areas of the service
          delivery that you need for your program.
        </p>
        <p>
          Is it dangerous to presume the financial state of any size of company
          in our industry from wide- ranging service portfolio to niche? Or can
          we just look at a companies’ history for any reassurance on how they
          will fare in this new world we are living in?
        </p>
        <p>
          Is size and scale the saviour? Are larger relocation companies better
          placed to weather the Covid storm which has devastated revenues and
          profits? Yes, they control more business but also have higher
          overheads and maybe are slower to react in the rapidly changing market
          and to the needs of their clients.
        </p>
        <p>
          Mid-sized to smaller relocation companies and the niche players still
          have the same issues as the larger companies but on different scales.
          Niche can be good if your niche is in demand or you are able to
          diversify affectively, but what happens if the niche isn’t in demand?
          Are small to mid-sized companies better able to make decisions quickly
          and effectively adapt to new market conditions?
        </p>
        <p>
          Potentially, but I believe this can be more effectively evaluated by
          looking at the structure of any sized company, the senior management,
          their track record of making quick and affective decisions to evolve
          their companies. Critically when decisions have been made how long did
          it take for those actions to become reality and did, they add value?
        </p>
        <p>
          Private Equity has invested in many relocation companies, but what is
          their appetite for continued investment, with potentially no or little
          return in the short term? If private equity investors decide to pull
          out and invest elsewhere it will be a huge disruptor for the industry.
        </p>
        <p>
          The challenge for many relocation companies, for example destination
          services or household goods moving providers is their reliance on
          being part of the supply chain for the larger to mid-sized relocation
          companies that control a large market share. What if a large or even
          mid-sized relocation company ran out of cash and went into
          liquidation! This would have a quick and dramatic effect on their
          supply chain with the consequence of taking some of these providers
          into liquidation as well. This scenario would affect many relocation
          companies as it is common for multiple relocation companies to use the
          same providers in their supply chain.
        </p>
        <p>
          In challenging times some companies do well as they find themselves in
          the right place at the right time! Look at the tech solution companies
          with Benivo continuing the evolution of their technology platform,
          Perchpeek as a new player in the market has seen expansion and a new
          $2m investment. So, it shows that investors still see the Global
          Mobility industry as a good investment but is this only in certain
          segments?
        </p>
        <p>
          On a more positive note, Covid has driven rapid change on all sides of
          our industry. For example, we had to adapt to remote working, dealing
          with closed borders, immigration delays, managing a home search
          virtually, getting household goods moves transported around the world
          in a pandemic. Who would have thought remote and virtual working would
          have taken up so much of everyone's time as multinational companies
          struggled with the concept, whilst many of their senior managers did
          not understand the compliance challenges!
        </p>
        <p>
          Even though COVID has been painful for Global Mobility, it was also a
          moment in time that opened the door to the amazing quality of talent
          and expertise in Global Mobility that led the business to embrace
          Global Mobility as a true partner at the table. Global Mobility
          professionals should congratulate themselves on what they have
          achieved in such challenging times and realise that COVID was a
          pivotal moment that changed Global Mobility positively in many ways.
        </p>
        <p>
          So, what can you do? My advice is to take time to ask the right
          questions of your providers, get regular financial updates and ensure
          you check their supply chain. I am available to share my experience
          and knowledge to help you evaluate if your provider and their supply
          chain are secure.
        </p>
        <p>
          Written by Paul Barnes, Director, Inspire Global Mobility Consulting.
        </p>
        <p>
          Email: <strong>paul@igmobility.com</strong> Mobile: +44 (0) 7534 565
          888
        </p>
      </article>
    </div>
  </Layout>
)

export default blog2
